@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@500;600;700;800&display=swap");

body {
  font-family: "Manrope", sans-serif;
  margin: 0;
  padding: 0;
}

.show-from-top {
  animation: show-from-top .3s ease 1 both;
}
.show-from-right {
  animation: show-from-right .7s ease 1 both;
}
.hide-to-left {
  animation: hide-to-left .4s ease 1 both;
  pointer-events: none;
}
.hide-to-top {
  animation: hide-to-top .3s ease 1 both;
  pointer-events: none;
}

.slide {
  opacity: 0;
  pointer-events: none;
}

.slide.show {
  opacity: 1;
  pointer-events: auto;
}

.slide.show .slide-content .icon {
  animation: show-from-right .7s ease 1 both;
}
.slide.show .slide-content h2 {
  animation: show-from-right .7s ease 1 both;
  animation-delay: .1s;
}
.slide.show .slide-content p {
  animation: show-from-right .7s ease 1 both;
  animation-delay: .2s;
}
.slide.show .slide-content .button-1 {
  animation: show-from-right .7s ease 1 both;
  animation-delay: .3s;
}
.slide.show .slide-content .button-2 {
  animation: show-from-right .7s ease 1 both;
  animation-delay: .4s;
}
.slide.show .frame {
  animation: show-from-right .7s ease 1 both;
  animation-delay: .3s;
}

/* hide */

.slide.hide .slide-content .icon {
  animation: hide-to-left .4s ease 1 both;
}
.slide.hide .slide-content h2 {
  animation: hide-to-left .4s ease 1 both;
  animation-delay: .1s;
}
.slide.hide .slide-content p {
  animation: hide-to-left .4s ease 1 both;
  animation-delay: .2s;
}
.slide.hide .slide-content .button-1 {
  animation: hide-to-left .4s ease 1 both;
  animation-delay: .3s;
}
.slide.hide .slide-content .button-2 {
  animation: hide-to-left .4s ease 1 both;
  animation-delay: .4s;
}
.slide.hide .frame {
  animation: hide-to-left .4s ease 1 both;
  animation-delay: .3s;
}

@keyframes hide-to-top {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-12px);
  }
}
@keyframes show-from-top {
  from {
    opacity: 0;
    transform: translateY(-12px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes show-from-right {
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes hide-to-left {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-100px);
  }
}